<template>
	<main class="pd:xl">
		<div class="preloader"></div>
	</main>
</template>

<script>
export default {
	name: 'auth',
	async created () {
		const auth = {
			type: ['wifi', 'data'].includes(this.$route.query.type) && JSON.parse(process.env.VUE_APP_FLOW_WIFI_IS_SUPPORTED || null) ? this.$route.query.type : 'data',
			uid: this.$route.query.suid,
			t: this.$route.query.t,
			s: this.$route.query.s,
			status: this.$route.query.status
		}

		const result = await this.$store.dispatch('auth/_set', auth)

		switch (result.code) {
			case 2001: {
				if (this.$route.query.pw || JSON.parse(this.$route.query.otp || null)) {
					this.$store.dispatch('ui/setModal', {
						name: 'modal-subscribed',
						data: {
							pw: this.$route.query.pw,
							otp: this.$route.query.otp
						}
					})
				} else {
					if (JSON.parse(process.env.VUE_APP_MODAL_AOC_IS_SHOWN || null)) {
						this.$store.dispatch('auth/prompt')
					}
				}

				// handle redirect
				let pathRedirect = this.$route.query.redirect ? this.$route.query.redirect : '/'
				pathRedirect = (pathRedirect.charAt(0) === '/' ? '' : '/') + pathRedirect

				this.$router.replace({ path: pathRedirect })

				break
			}

			default: {
				this.$router.replace({ path: '/' }, _ => {
					window.location.reload()
				})

				console.warn(result.description)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
main {
	display: flex;
	background-color: $color-background-invert;
	color: $color-foreground-invert;

	.preloader {
		margin: auto;
	}
}
</style>
